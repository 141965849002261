import {
  CHANGE_ORDER_STATUS,
  SET_ALL_ORDER,
  UPDATE_ORDER,
} from "../actions/OrderActions";

const initialState = [];

export default function AllOrderReducer(state = initialState, action) {
  if (action.type === SET_ALL_ORDER) {
    return state.length ? [...state, { ...action.payload }] : [action.payload];
  }
  if (action.type === UPDATE_ORDER) {
    return state.length ? [...state, { ...action.payload }] : [action.payload];
  }
  if (action.type === CHANGE_ORDER_STATUS) {
    return state.map((item) => {
      if (item.type === action.payload.type) {
        return {
          ...item,
          data: item.data.map((it) => {
            if (it.id === action.payload.data.id) {
              return action.payload.data;
            } else return it;
          }),
        };
      } else return item;
    });
  }
  return state;
}
